<template>
  <NavQuestion
    :heading="heading"
    :number="'7'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="charityMinPercentage + '%'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-1-percent.png'"
        :selected="
          Number(data.distribution_percentage) === charityMinPercentage
        "
        @selected="setPercentage(charityMinPercentage)"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'5%'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-5-percent.png'"
        :selected="
          Number(data.distribution_percentage) === alternativePercentage
        "
        @selected="setPercentage(alternativePercentage)"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="otherPercentageLabel"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-custom-percent.png'"
        :selected="
          Number(data.distribution_percentage) !== alternativePercentage &&
          Number(data.distribution_percentage) !== charityMinPercentage &&
          data.distribution_percentage !== null
        "
        @selected="show.modal = true"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
      <CharityPercentageModal
        v-show="show.modal"
        @close="show.modal = false"
        @change="data.distribution_percentage = $event"
        :minPercentage="charityMinPercentage"
        :initialPercentage="data.distribution_percentage"
      ></CharityPercentageModal>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'
import CharityPercentageModal from './Modals/CharityPercentageModal'
import { http } from '@/services'

export default {
  name: 'ProfileCharityPercentage',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall,
    CharityPercentageModal
  },
  watch: {
    charity: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.charity_name = value.name
          this.data.charity_number = value.charity_number
        } else {
          this.data.charity_name = null
          this.data.charity_number = null
        }
      }
    },
    charityDistribution: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.id = value.id
          this.data.distribution_percentage = Number(
            value.distribution_percentage
          )
        } else {
          this.data.id = null
          this.data.distribution_percentage = null
        }
      }
    }
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    isValidated() {
      if (
        this.data.distribution_percentage &&
        this.data.charity_name &&
        this.data.charity_number
      )
        return true
      return false
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    },
    charity() {
      if (
        this.checkoutBasket &&
        this.checkoutBasket.coupon &&
        this.checkoutBasket.coupon.charity
      )
        return this.checkoutBasket.coupon.charity
      return null
    },
    charityMinPercentage() {
      if (this.charity) return this.charity.min_percentage
      return ''
    },
    charityDistribution() {
      return this.$store.getters.charities.find(
        (charity) => charity.charity_protected
      )
    },
    charityGift() {
      return this.$store.getters.giftCharity.find(
        (gift) => gift.charity_protected
      )
    },
    heading() {
      if (this.charity)
        return (
          'What percentage of your estate should pass to ' + this.charity.name
        )
      return ''
    },
    subHeading() {
      if (this.charity) {
        return (
          'You can gift any percentage from ' +
          this.charity.min_percentage +
          '% upwards. The legacy will be paid only after any debts, ' +
          'funeral costs and other expenses have been paid. If you are making a Will with your partner the gift will be added to both Wills and ' +
          'paid only once you have both passed away.'
        )
      }
      return ''
    },
    otherPercentageLabel() {
      if (
        this.data.distribution_percentage &&
        this.data.distribution_percentage !== this.charityMinPercentage &&
        this.data.distribution_percentage !== this.alternativePercentage
      )
        return this.data.distribution_percentage + '%'
      return 'Other Percentage'
    },
    start() {
      return (
        this.$router.history.current.name === 'WriteMyWillCharityPercentage'
      )
    },
    forwardTo() {
      if (this.start) return '/write_my_will/address'
      return '/profile/address'
    },
    backTo() {
      if (this.start) return '/write_my_will/charity_option'
      return '/profile/charity_option'
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  data() {
    return {
      show: {
        modal: false
      },
      data: {
        id: null,
        distribution_percentage: null,
        distribution_primary: true,
        charity_name: null,
        charity_number: null,
        charity_protected: true
      },
      alternativePercentage: 5
    }
  },
  methods: {
    setPercentage(value) {
      this.data.distribution_percentage = value
    },
    save() {
      if (this.data.id) this.patch()
      else this.post()
    },
    patch() {
      http
        .patch('/wills/api/charity', this.data)
        .then((response) => {
          this.$store.commit('charityAdd', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    post() {
      http
        .post('/wills/api/charity', this.data)
        .then((response) => {
          this.$store.commit('charityAdd', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      this.removeCharityGift()
    },
    removeCharityGift() {
      if (this.charityGift) {
        this.$store.dispatch('deleteCharityGiftFromServer', this.charityGift.id)
      }
    }
  }
}
</script>
