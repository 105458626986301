<template>
  <BaseModalSmall
    @close="close"
    v-bind:isValidated="validateForm"
    @confirm="confirm"
  >
    <template v-slot:header>Other Percentage</template>
    <template v-slot:body>
      <div class="input-container">
        <input
          v-model="percentage"
          :min="minPercentage"
          type="number"
          placeholder="Percentage"
          class="input-standard-grey validation"
          id="percentage"
        />
        <label for="percentage">Percentage</label>
      </div>
    </template>
    <template v-slot:button> Update </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'CharityPercentageModal',
  props: ['initialPercentage', 'minPercentage'],
  components: {
    BaseModalSmall
  },
  watch: {
    initialPercentage: {
      deep: true,
      immediate: true,
      handler(value) {
        this.percentage = value
      }
    }
  },
  computed: {
    validateForm() {
      if (this.percentage && this.percentage >= this.minPercentage) return true
      return false
    }
  },
  data() {
    return {
      percentage: null
    }
  },
  methods: {
    confirm() {
      this.$emit('change', this.percentage)
      this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
